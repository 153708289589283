<template>
  <div class="areaUserList">
    <!-- 项目区域-负责人员 -->
    <el-container class="areaUserList_container">
      <el-main>
        <head-layout
          head-title="人员清单"
          :showIcon="false"
          :head-btn-options="headBtnOptions"
          @head-add-tabs="headAdd"
          @head-remove="headRemove"
        />
        <!--  -->
        <grid-layout
          class="serviceType"
          ref="gridLayOut"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @page-current-change="handleCurrentChange"
          @page-size-change="handleSizeChange"
          @page-refresh-change="onLoad"
          @gird-handle-select-click="selectionChange"
          :grid-row-btn="gridRowBtn"
          @row-edit="rowEdit"
          @row-remove="rowRemove"
        >
        </grid-layout>
      </el-main>
    </el-container>
    <!-- 操作 -->
    <el-dialog
      :visible.sync="dialogVisible"
      title="负责人员"
      :before-close="handleCancel"
    >
      <avue-form
        :option="dialogOption"
        ref="cedForm"
        v-model="dataForm"
        @submit="handleSubmit"
      >
        <template slot="dutyUnit">
          <el-input ref="dutyUnitInput" v-model="dataForm.dutyUnitName" @focus="handleChangeDept('dutyUnit')" suffix-icon="el-icon-search"></el-input>
        </template>
        <template slot="dutyUser">
          <el-input ref="dutyUserInput" v-model="dataForm.dutyUserName" @focus="handleChangeUser('dutyUser')" suffix-icon="el-icon-search"></el-input>
        </template>
        <template slot="safetyUser">
          <el-input ref="safetyUserInput" v-model="dataForm.safetyUserName" @focus="handleChangeUser('safetyUser')" suffix-icon="el-icon-search"></el-input>
        </template>
        <template slot="manageUser">
          <el-input ref="manageUserInput" v-model="dataForm.manageUserName" @focus="handleChangeUser('manageUser')" suffix-icon="el-icon-search"></el-input>
        </template>
      </avue-form>
      <template slot="footer">
        <el-button
          size="small"
          @click="handleCancel()"
          v-loading="dialogLoading"
        >
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleSave"
          v-loading="dialogLoading"
        >
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </template>
    </el-dialog>

    <el-dialog title="人员选择" :visible.sync="userShow" width="80%" top="8vh">
      <UserDetpDialog
        ref="UserDetpDialog"
        @select-data="selectUserData"
      ></UserDetpDialog>
    </el-dialog>

    <el-dialog title="部门选择" :visible.sync="deptShow" width="80%">
      <DeptDialog ref="DeptDialog" deptChild @select-data="selectDept"></DeptDialog>
    </el-dialog>
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout.vue";
import GridLayout from "@/views/components/layout/grid-layout.vue";
import {
  prjAreaUserPage,
  prjAreaUserSubmit,
  prjAreaUserRemove,
  pmRiskUserRemove,
  pmRiskUserSubmit, pmRiskUserPage
} from '@/api/safe/projectArea.js'
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog.vue";
import DeptDialog from "@/views/components/UserDeptDialog/DeptDialog";

import { mapGetters } from "vuex";
import {getDictionaryCode} from '@/api/system/dictbiz.js'
export default {
  components: {
    HeadLayout,
    GridLayout,
    UserDetpDialog,
    DeptDialog
  },

  props: {
    riskLibraryId: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      query: {},
      // prjId: "", // 项目id
      selectionList: [],
      dataForm: {
        prjAreaId: '',
        postId: '',
        dutyUnit: '',
        dutyUnitName: '',
        dutyUser: '',
        dutyUserName: '',
        dutyUserPhone: '',
        safetyUser: '',
        safetyUserName: '',
        safetyUserPhone: '',
        manageUser: '',
        manageUserName: '',
        manageUserPhone: '',
      },
      postDicCode: '',
      postList: [],
      dialogVisible: false,
      dialogLoading: false,

      deptShow: false,
      userShow: false,
      userType: ''
    };
  },

  computed: {
    ...mapGetters(["permission", "colorName"]),
    headBtnOptions() {
      if (this.$route.query.type != "view") {
        return [
          {
            label: this.$t(`cip.cmn.btn.addBtn`),
            emit: "head-add-tabs",
            type: "button",
            icon: "",
            btnOptType: "add",
          },
          {
            label: this.$t("cip.cmn.btn.delBtn"),
            emit: "head-remove",
            type: "button",
            icon: "",
          },
        ];
      }
    },

    tableOptions() {
      return {
        //   menu:this.$route.query.type != 'view',
        //   selection: this.$route.query.type != "view",
        column: [
          // {
          //   label: "专业",
          //   prop: "postId",
          //   align: "center",
          //   overHidden: true,
          //   dicData: this.postList,
          //   // dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=${this.postDicCode}`,
          //   props: {
          //     value: 'dictKey',
          //     label: 'dictValue',
          //   }
          // },
          {
            label: "责任单位",
            prop: "dutyUnitName",
            align: "center",
            minWidth: 200,
            overHidden: true,
          },
          {
            label: "责任人",
            prop: "dutyUserName",
            align: "center",
            overHidden: true,
          },
          {
            label: "联系方式",
            prop: "dutyUserPhone",
            minlength: 120,
            align: "center",
            overHidden: true,
          },
          {
            label: "安全负责人",
            prop: "safetyUserName",
            minWidth: 100,
            align: "center",
            overHidden: true,
          },
          {
            label: "联系方式",
            prop: "safetyUserPhone",
            minlength: 120,
            align: "center",
            overHidden: true,
          },
          {
            label: "管理人员",
            prop: "manageUserName",
            align: "center",
            overHidden: true,
          },
          {
            label: "联系方式",
            prop: "manageUserPhone",
            minlength: 120,
            align: "center",
            overHidden: true,
          },
        ],
      };
    },

    gridRowBtn() {
      // if (this.$route.query.type != "view") {
      return [
        {
          label: this.$t(`cip.cmn.btn.editBtn`),
          emit: "row-edit",
          type: "button",
          icon: "",
        },
        {
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "row-remove",
          type: "button",
          icon: "",
        },
      ];
      // }
    },

    dialogOption () {
      return {
        size: 'small',
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          // {
          //   label: "专业/岗位",
          //   prop: "postId",
          //   placeholder: '请选择专业/岗位',
          //   span: 12,
          //   type: 'select',
          //   // dicData: this.postList,
          //   dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=${this.postDicCode}`,
          //   props: {
          //     value: 'dictKey',
          //     label: 'dictValue',
          //   },
          //   rules: [{
          //     required: true,
          //     message: '请选择专业/岗位',
          //     trigger: 'change'
          //   }],
          // },
          {
            label: "责任单位",
            prop: "dutyUnit",
            placeholder: '请选择责任单位',
            formslot: true,
            span: 24,
            rules: [{
              required: true,
              message: "请选择",
              trigger: "change"
            }]
          },
          {
            label: "责任人",
            prop: "dutyUser",
            placeholder: "请选择责任人",
            formslot: true,
            span:12,
            rules: [{
              required: true,
              message: "请选择",
              trigger: "change"
            }]
          },
          {
            label: "联系方式",
            prop: "dutyUserPhone",
            placeholder: '请先选择责任人',
            span: 12,
            disabled: true,
          },
          {
            label: "安全负责人",
            prop: "safetyUser",
            placeholder: "请选择安全负责人",
            formslot: true,
            span:12,
            rules: [{
              required: true,
              message: "请选择",
              trigger: "change"
            }]
          },
          {
            label: "联系方式",
            prop: "safetyUserPhone",
            placeholder: '请先选择安全负责人',
            span: 12,
            disabled: true,
          },
          {
            label: "管理人员",
            prop: "manageUser",
            placeholder: "请选择管理人员",
            formslot: true,
            span:12,
            rules: [{
              required: true,
              message: "请选择",
              trigger: "change"
            }]
          },
          {
            label: "联系方式",
            prop: "manageUserPhone",
            placeholder: '请先选择管理人员',
            span: 12,
            disabled: true,
          },
        ]
      }
    },
  },

  mounted () {
  this.getDic()
  this.onLoad(this.page)
  },

  methods: {
    init () {
      this.getDic()
      this.onLoad(this.page)
    },
    getDic () {
      this.postDicCode = this.deptSegment == 1 ? 'ST_MAJOR_CONS' : this.deptSegment == 2 ? 'ST_MAJOR_ENV' : this.deptSegment == 3 ? 'ST_MAJOR_MINE' : ''
      getDictionaryCode(this.postDicCode).then((res) => {
        this.postList = res.data.data;
      })
    },
    /** 右侧表格操作 */
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      params.riskLibraryId = this.riskLibraryId
      pmRiskUserPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        // this.$refs.gridLayOut.page.total = data.total;
        this.page.total = data.total
        this.tableData = data.records;
        this.tableLoading = false;
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
        });
        this.$refs.gridLayOut.selectionClear();
      });
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page);
    },
    //size改变
    handleSizeChange(e) {
      this.page.currentPage = 1;
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page);
    },
    // 选择列表数据
    selectionChange(list) {
      this.selectionList = list;
    },
    // 批量删除
    headRemove() {
      let selectionList = this.$refs.gridLayOut.selectionList;
      if (selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      let ids = selectionList.map(val=>val.id).toString()
      this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          pmRiskUserRemove(ids).then(() => {
            this.onLoad(this.page);
            this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"))
          })
        });
    },
    rowEdit(row, index) {
      for (let key in this.dataForm){
        if(key in row){
          this.dataForm[key] = row[key]
        } else {
          this.dataForm[key] = ''
        }
      }
      this.dataForm.id = row.id
      this.dataForm.postName = this.postDicCode
      this.dialogVisible = true
      // this.$refs.projectAreaAdd.init("edit", row);
    },
    // 删除
    rowRemove(row) {
      this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          pmRiskUserRemove(row.id).then(() => {
            this.onLoad(this.page);
            this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"))
          })
        });
    },
    headAdd() {
      for (let key in this.dataForm){
        this.dataForm[key] = ''
      }
      delete this.dataForm.id
      this.dataForm.postName = this.postDicCode
      this.dialogVisible = true
      // this.$nextTick(()=>{
      //     this.$refs.cedForm.resetFields()
      // })
    },
    handelCancel () {
      this.dialogVisible = false
    },
    handleChangeDept (type) {
      this.deptShow = true
    },

    selectDept (data) {
      this.dataForm.dutyUnit = data.id
      this.dataForm.dutyUnitName = data.deptName
      this.deptShow = false
    },

    handleChangeUser (type) {
      this.userType = type
      this.userShow = true
    },
    selectUserData (data) {
      this.dataForm[this.userType] = data.id
      this.dataForm[`${this.userType}Name`] = data.realName
      this.dataForm[`${this.userType}Phone`] = data.phone
      this.userShow = false
    },

    handleSave () {
      this.$refs.cedForm.submit()
    },

    handleSubmit () {
      this.$refs.cedForm.validate((valid, done) => {
        if(valid){
          this.dialogLoading = true
          let params = {...this.dataForm}
          if(!this.dataForm.id)params.riskLibraryId = this.riskLibraryId
          pmRiskUserSubmit(params).then(res => {
            this.$message.success("操作成功")
            this.onLoad(this.page)
            this.handleCancel()
            done()
            this.dialogLoading = false
          }).catch(()=>{
            done()
            this.dialogLoading = false
          })
        }
      })
    },

    handleCancel () {
      this.dialogVisible = false
    }

  },
};
</script>

<style lang="scss" scoped>
</style>
