<template>
  <div class="add">
    <el-container>
      <el-main style="background: #ffffff">
        <head-layout
          @head-save="headSave"
          @head-complete="headComplete"
          @head-cancel="headCancel"
          :head-btn-options="headBtnOptions"
          head-title="风险信息"
        >
        </head-layout>
        <div style="padding: 12px">
          <el-form
            ref="form"
            :model="ruleForm"
            :rules="rules"
            label-width="160px"
            :disabled="type == 'detail'"
          >
            <el-row>
              <el-col :span="8">
                <el-form-item label="风险编号:" prop="riskCode">
                  <el-input
                    type="text"
                    v-model="ruleForm.riskCode"
                    placeholder="请输入风险编号"
                    maxlength="64"
                    show-word-limit
                  >
                    <span slot="append">
                      自动生成
                      <el-switch
                        v-model="getRiskCode"
                        @change="handleSwitch"
                      ></el-switch>
                    </span>
                  </el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="3">
                <el-form-item label="自动生成:">
                  <el-switch
                    @change="handleSwitch"
                    v-model="getRiskCode"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                  >
                  </el-switch>
                </el-form-item>
              </el-col> -->
              <el-col :span="16">
                <el-form-item label="风险描述:" prop="riskName">
                  <el-input
                    type="text"
                    v-model="ruleForm.riskName"
                    placeholder="请输入风险描述"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="风险源:" prop="sourceCode">
                  <el-select
                    v-model="ruleForm.sourceCode"
                    @change="handleSource"
                    placeholder="请选择风险源"
                    clearable
                    disabled
                  >
                    <el-option
                      v-for="item in riskSourceList"
                      :key="item.sourceCode"
                      :label="item.sourceName"
                      :value="item.sourceCode"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="3">
                <el-form-item label=""> </el-form-item>
              </el-col> -->
              <el-col :span="8">
                <el-form-item label="事故类型:" prop="event">
                  <!--                  <el-input-->
                  <!--                    type="input"-->
                  <!--                    v-model="ruleForm.event"-->
                  <!--                    placeholder="请输入事故类型"-->
                  <!--                    maxlength="64"-->
                  <!--                    show-word-limit-->
                  <!--                  ></el-input>-->
                  <el-select
                    v-model="ruleForm.event"
                    multiple
                    placeholder="请选择事故类型"
                  >
                    <el-option
                      v-for="item in eventList"
                      :key="item.dictKey"
                      :label="item.dictValue"
                      :value="item.dictKey"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="deptIdList" label="责任部门">
                  <el-input
                    v-model="ruleForm.responsibleDep"
                    @focus="handleDeptModeOpenForm()"
                    clearable
                    placeholder="请选择责任部门"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="后果影响描述:" prop="consequence">
                  <el-input
                    type="textarea"
                    placeholder="请输入内容"
                    v-model="ruleForm.consequence"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="L:">
                  <el-input-number
                    v-model="ruleForm.lecdLScore"
                    @input="handleChange"
                    :min="0"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="E:">
                  <el-input-number
                    v-model="ruleForm.lecdEScore"
                    @input="handleChange"
                    :min="0"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="C:">
                  <el-input-number
                    v-model="ruleForm.lecdCScore"
                    @input="handleChange"
                    :min="0"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="5" class="class-D">
                <el-form-item label="D:">
                  <el-input
                    style="width: calc(100% - 30px)"
                    v-model="ruleForm.lecdDScore"
                    :min="0"
                    default="0"
                    :disabled="true"
                  ></el-input>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="LEC风险评价法：L（Likelihood，事故发生的可能性）、E（Exposure，人员暴露于危险环境中的频繁程度）、C（Consequence，一旦发生事故可能造成的后果）。给三种因素的不同等级分别确定不同的分值，再以三个分值的乘积D（danger，危险性）来评价风险的大小。注：自行选择风险等级后D值会清空，采用用户的选择"
                    placement="right-start"
                  >
                    <i
                      class="el-icon-question"
                      style="margin-left: 10px; font-size: 20px"
                    ></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="风险等级:">
                  <!-- <el-input
                    v-model="ruleForm.riskLevel"
                    placeholder="系统自动计算"
                    disabled
                  ></el-input> -->
                  <el-select
                    v-model="ruleForm.riskLevel"
                    placeholder="系统自动计算（也可自行选择）"
                    clearable
                    @change="riskLevelChange"
                  >
                    <el-option
                      v-for="item in D"
                      :label="item.levelName"
                      :value="item.levelName"
                      :key="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="工程技术" prop="treatEngineer">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 3 }"
                    show-word-limit
                    v-model="ruleForm.treatEngineer"
                    maxlength="1000"
                    placeholder="请输入工程技术内容"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="管理措施" prop="treatManagment">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 3 }"
                    show-word-limit
                    v-model="ruleForm.treatManagment"
                    maxlength="1000"
                    placeholder="请输入管理措施内容"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="培训教育" prop="treatTraining">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 3 }"
                    show-word-limit
                    v-model="ruleForm.treatTraining"
                    maxlength="1000"
                    placeholder="请输入培训教育内容"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="个体防护" prop="treatProtection">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 3 }"
                    show-word-limit
                    v-model="ruleForm.treatProtection"
                    maxlength="1000"
                    placeholder="请输入个体防护内容"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="应急处置" prop="treatEmergency">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 3 }"
                    show-word-limit
                    v-model="ruleForm.treatEmergency"
                    maxlength="1000"
                    placeholder="请输入个体防护内容"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-main>
    </el-container>
    <userList
      v-show="this.nowRuleFormId"
      ref="userList"
      :riskLibraryId="this.nowRuleFormId"
    ></userList>
    <el-dialog
      title="人员选择"
      :visible.sync="personShow"
      width="80%"
      top="8vh"
    >
      <UserDetpDialog
        ref="UserDetpDialog"
        @select-data="selectData"
      ></UserDetpDialog>
      <!-- <UserDeptMultipleDialog
        ref="UserDeptMultipleDialog"
        @select-all-data="selectData"
      ></UserDeptMultipleDialog> -->
    </el-dialog>
    <el-dialog title="部门选择" :visible.sync="deptShow" width="80%">
      <DeptDialog
        ref="DeptDialog"
        @select-all-data="selectData1"
        @select-data="selectRowData1"
        :selection="true"
      ></DeptDialog>
    </el-dialog>
    <el-dialog title="部门选择" :visible.sync="deptShowForm" width="80%">
      <DeptDialog :parentDeptId="organizationId" ref="DeptDialog" @select-data="selectData2"></DeptDialog>
    </el-dialog>
  </div>
</template>

<script>
import CommonTree from "@/views/components/com_tree/index";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import userList from "@/views/business/riskProject/userList";
import { selectChildren } from "@/api/system/dept";
import { mapGetters } from "vuex";
import { getDictionaryCode } from "@/api/system/dictbiz";
import {
  getbsRiskLecdLMap,
  getbsrisklecdeMap,
  getbsRiskLecdCMap,
  getbsrisklecddlevelMap,
} from "@/api/setting/riskAssessment/index";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog";
import UserDeptMultipleDialog from "@/views/components/UserDeptDialog/UserDeptMultipleDialog.vue";
import DeptDialog from "@/views/components/UserDeptDialog/DeptDialog";
import {
  addPage,
  PageDetail,
  getPageCode,
  deleteTreatmentTree, // 删除措施分类
  deleteRisktreatmentPage, // 删除措施
  getRiskTreatmentTypeDetail,
  deletePmRiskMeasure,
  getRiskManagementAndControLlist,
} from "@/api/riskManage/project";

export default {
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
    UserDetpDialog,
    DeptDialog,
    UserDeptMultipleDialog,
    userList,
  },
  computed: {
    ...mapGetters(["userInfo"]),
    headBtnOptions() {
      let buttonBtn = [];
      if (this.type !== "detail") {
        buttonBtn.push({
          label: "保存",
          emit: "head-save",
          type: "button",
          icon: "",
          btnOptType: "save",
        });
        // buttonBtn.push(
        //   {
        //     label: "完成",
        //     emit: "head-complete",
        //     type: "button",
        //     icon: "",
        //     btnOptType: "complete",
        //   });
      }
      buttonBtn.push({
        label: "取消",
        emit: "head-cancel",
        type: "button",
        icon: "",
        btnOptType: "cancel",
      });
      return buttonBtn;
    },
  },
  data() {
    return {
      deptShow: false,
      deptShowForm: false,
      personShow: false,
      riskTreatmentList: [],
      showTreeDialog: false,
      showTreeListDialog: false,
      treeForm: {},
      dataArray: [],
      defaultExpandedKey: [],
      rules: {
        riskCode: {
          required: true,
          message: "请输入或自动生成编码",
          trigger: "blur",
        },
        riskName: {
          required: true,
          message: "请输入风险描述",
          trigger: "blur",
        },
        sourceCode: {
          required: true,
          message: "请选择风险源",
          trigger: "blur",
        },
        // consequence: {
        //   required: true,
        //   message: "请输入后果影响描述",
        //   trigger: "blur",
        // },
        event: {
          required: true,
          message: "请输入事故类型",
          trigger: "blur",
        },
        treatEngineer: [
          { required: true, message: "请输入工程技术", trigger: "blur" },
        ],
        treatManagment: [
          { required: true, message: "请输入管理措施", trigger: "blur" },
        ],
        treatTraining: [
          { required: true, message: "请输入培训教育", trigger: "blur" },
        ],
        treatProtection: [
          { required: true, message: "请输入个体防护", trigger: "blur" },
        ],
        treatEmergency: [
          { required: true, message: "请输入应急处置", trigger: "blur" },
        ],
      },
      listRules: {
        treatEngineer: {
          required: true,
          message: "请输入工程技术",
          trigger: "blur",
        },
        treatManagment: {
          required: true,
          message: "请输入管理措施",
          trigger: "blur",
        },
        treatTraining: {
          required: true,
          message: "请输入培训教育",
          trigger: "blur",
        },
        treatProtection: {
          required: true,
          message: "请输入个体防护",
          trigger: "blur",
        },
        treatEmergency: {
          required: true,
          message: "请输入应急处置",
          trigger: "blur",
        },
      },
      listForm: {
        treatEngineer: "",
        treatManagment: "",
        treatTraining: "",
        treatProtection: "",
        treatEmergency: "",
      },
      selectOptions: [],
      controlLevelArr: [],
      treeDialogOption: {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: "上级措施分类",
            prop: "parentId",
            type: "tree",
            dicData: [],
            value: "-1",
            span: 24,
            props: {
              label: "treatmentTypeName",
              value: "id",
            },
          },
          {
            label: "措施分类名称",
            prop: "treatmentTypeName",
            type: "input",
            maxlength: 100,
            overHidden: true,
            span: 24,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入名称",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      form: {},
      deptTreeData: [],
      defaultProps: {
        children: "children",
        label: "treatmentTypeName",
        value: "id",
      },
      searchTitle: "treatmentTypeName",
      ruleForm: {
        needCalculate: 1,
        riskLevel: "",
        riskSource: "",
      },
      treeData: [],
      tableLoading: false,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      tableData: [],
      subTableheadBtnOptions: [
        {
          label: "新增",
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: "add",
        },
        {
          label: "删除",
          emit: "head-delete-tabs",
          type: "button",
          icon: "",
          btnOptType: "delete",
        },
      ],
      headBtnOptionsDetail: [
        {
          label: "取消",
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: "cancel",
        },
      ],
      gridRowBtn: [
        {
          label: "编辑",
          emit: "row-edit",
          type: "button",
          icon: "",
          btnOptType: "row-edit",
        },
        {
          label: "删除",
          emit: "row-delete",
          type: "button",
          icon: "",
          btnOptType: "row-delete",
        },
      ],
      subTableOption: {
        customRowAddBtn: false,
        menuFixed: "right",
        menuWidth: 100,
        showTooltip: true,
        indexLabel: "序号",
        index: true,
        column: [
          {
            label: "现有控制措施",
            children: [
              {
                label: "工程技术",
                width: 300,
                align: "left",
                overHidden: true,
                prop: "treatEngineer",
              },
              {
                label: "管理措施",
                width: 300,
                align: "left",
                overHidden: true,
                prop: "treatManagment",
              },
              {
                label: "培训教育",
                width: 300,
                align: "left",
                overHidden: true,
                prop: "treatTraining",
              },
              {
                label: "个体防护",
                width: 300,
                align: "left",
                overHidden: true,
                prop: "treatProtection",
              },
              {
                label: "应急处置",
                width: 300,
                align: "left",
                overHidden: true,
                prop: "treatEmergency",
              },
            ],
          },
        ],
      },
      L: [],
      E: [],
      C: [],
      D: [],
      riskSourceList: [],
      eventList: [],
      getRiskCode: true,
      risktreatmentId: "",
      nowRuleFormId: "",
      type: "add",
      ids: "",
      nowNodeTree: {},
      tableTotal: 0,
      riskVersionId: "",
      organizationId: "",
      controlLevelMap: {},
    };
  },
  created() {
    this.riskVersionId = this.$route.query.riskVersionId;
    this.organizationId = this.$route.query.organizationId;
    this.getPageCode();
    this.getRisksourceList();
    this.getbsRiskLecdLMap();
    this.getbsrisklecdeMap();
    this.getbsRiskLecdCMap();
    this.getbsrisklecddlevelMap();
    this.getTreeData();
    this.getDictionaryCode();
    this.getAccidentTypeList();
    if (this.$route.query.nowRuleFormId) {
      this.nowRuleFormId = this.$route.query.nowRuleFormId;
      this.getPageDetail();
    }
    this.type = this.$route.query.type;
    if (this.type == "add") {
      this.ruleForm.responsibleDepId = this.userInfo.dept_id;
      this.ruleForm.responsibleDep = this.userInfo.deptName;
      this.ruleForm.sourceId = this.$route.query.sourceId;
      this.ruleForm.sourceName = this.$route.query.sourceName;
      this.ruleForm.sourceCode = this.$route.query.sourceName;
    }
    if (this.type == "workflow") {
      this.nowRuleFormId = this.$route.query.id;
      this.getPageDetail();
    }
  },
  methods: {
    riskLevelChange(val) {
      this.D.map((item) => {
        if (val == item.levelName) {
          this.ruleForm.lecdColor = item.color;
          this.ruleForm.lecdD = item.levelCode;
          this.needCalculate = 0;
        }
      });
    },
    changeRespRole(id, data) {
      this.listForm.respRoleName = data.roleName;
    },
    changeRespPost(id, data) {
      this.listForm.respPostName = data.postName;
    },
    selectData(row) {
      this.personShow = false;
      this.listForm.respUserId = row.id;
      this.listForm.respUserName = row.realName;
      this.listForm.respUserAccount = row.account;
      this.listForm.respNickName = row.nickName;

      // let newRow = row.selectAllData.map(item => {
      //   return {
      //     ...item,
      //     deptId:row.treeDeptId,
      //     deptName:row.treeDeptName,
      //     userId: item.id,
      //     userName: item.account,
      //     id: '',
      //     actId: this.dataForm.id,
      //   }
      // })
    },
    selectData1(list) {
      this.deptShow = false;
      this.listForm.respDeptId = list
        .map((item) => {
          return item.id;
        })
        .join(",");
      this.listForm.respDeptName = list
        .map((item) => {
          return item.deptName;
        })
        .join(",");
      // this.listForm.respDeptId = row.id;
      // this.listForm.respName = row.deptName;
    },
    selectRowData1(row) {
      this.deptShow = false;
      this.listForm.respDeptId = String(row.id);
      this.listForm.respDeptName = row.deptName;
    },
    selectData2(row) {
      this.deptShowForm = false;
      this.ruleForm.responsibleDepId = row.id;
      this.ruleForm.responsibleDep = row.deptName;
    },
    handleUserModeOpen() {
      this.personShow = true;
    },
    handleDeptModeOpen() {
      this.deptShow = true;
    },
    handleDeptModeOpenForm() {
      this.deptShowForm = true;
    },
    getPageDetail() {
      PageDetail({
        id: this.nowRuleFormId,
        // organizationId: this.userInfo.dept_id,
      }).then((res) => {
        let data = JSON.parse(JSON.stringify(res.data.data));
        if (data.event) {
          data.event = data.event.split(",");
        }
        this.ruleForm = data;
        if (this.ruleForm.event != "" && this.ruleForm.event) {
          this.ruleForm.eventList = this.ruleForm.event.split(",");
        }
        this.ruleForm.riskSource = {
          id: this.ruleForm.sourceId,
          sourceName: this.ruleForm.sourceName,
        };
        // if (this.ruleForm.lecdDScore == -1) {
        //   this.$set(this.ruleForm, "lecdDScore", "");
        // }
        setTimeout(() => {
          this.ruleForm.needCalculate = 1;
        }, 100);
        if (!this.ruleForm.riskLevel) {
          this.computedriskLevel();
        }
      });
    },
    // 计算风险等级
    computedriskLevel() {
      window.D = this.ruleForm.lecdDScore;
      const level = this.D.find((item) => {
        if (window.eval(item.formula)) {
          return item;
        }
      });
      if (level) {
        this.ruleForm.riskLevel = level.levelName;
        this.ruleForm.lecdColor = level.color;
        this.ruleForm.lecdD = level.levelCode;
      }
      return level || {};
    },
    // 计算D值
    computedlecdDScore() {
      this.ruleForm.lecdDScore =
        this.ruleForm.lecdLScore *
          this.ruleForm.lecdEScore *
          this.ruleForm.lecdCScore || 0;
      this.computedriskLevel();
    },
    handleChange() {
      this.needCalculate = 1;
      if (this.ruleForm.needCalculate == 1) {
        this.computedlecdDScore();
      }
      // this.computedlecdDScore();
    },
    handleChangeL(e) {
      const result = this.L.find((item) => item.id == e);
      this.ruleForm.lecdL = result.definition;
      this.ruleForm.lecdLScore = result.score;
      this.computedlecdDScore();
    },
    handleChangeE(e) {
      const result = this.E.find((item) => item.id == e);
      this.ruleForm.lecdE = result.definition;
      this.ruleForm.lecdEScore = result.score;
      this.computedlecdDScore();
    },
    handleChangeC(e) {
      const result = this.C.find((item) => item.id == e);
      this.ruleForm.lecdC = result.definition;
      this.ruleForm.lecdCScore = result.score;
      this.computedlecdDScore();
    },
    // 获取L映射
    getbsRiskLecdLMap() {
      // createDept: this.userInfo.dept_id
      getbsRiskLecdLMap({}).then((res) => {
        this.L = res.data.data;
      });
    },
    // 获取E映射
    getbsrisklecdeMap() {
      // createDept: this.userInfo.dept_id
      getbsrisklecdeMap({}).then((res) => {
        this.E = res.data.data;
      });
    },
    // 获取C映射
    getbsRiskLecdCMap() {
      // createDept: this.userInfo.dept_id
      getbsRiskLecdCMap({}).then((res) => {
        this.C = res.data.data;
      });
    },
    // 获取D映射
    getbsrisklecddlevelMap() {
      // createDept: this.userInfo.dept_id
      getbsrisklecddlevelMap({}).then((res) => {
        this.D = res.data.data;
      });
    },
    // 获取风险源映射
    getRisksourceList() {
      getRiskManagementAndControLlist({
        organizationId: this.organizationId,
        riskVersionId: this.riskVersionId,
      }).then((res) => {
        if (res.data.code == 200) {
          this.riskSourceList = res.data.data;
          if (this.$route.query.riskSource) {
            const result = this.riskSourceList.find((item) => {
              return item.id == this.$route.query.riskSource;
            });
            this.ruleForm.riskSource = result;
          }
        }
      });
    },
    // 获取事故类型
    getAccidentTypeList() {
      // RISK_EVENT_TYPE
      getDictionaryCode("risk_envent_type").then((res) => {
        if (res.data.code == 200) {
          this.eventList = res.data.data;
        }
      });
    },
    // 获取隐患管控层级下拉
    getDictionaryCode() {
      getDictionaryCode("risk_control_level").then((res) => {
        this.controlLevelArr = res.data.data;
        this.controlLevelArr.map((item) => {
          this.controlLevelMap[item.dictKey] = item.dictValue;
        });
      });
    },
    getTreeData() {
      selectChildren().then((res) => {
        if (res.data.code == 200) {
          this.deptTreeData = res.data.data;
          if (this.type == "view") {
            this.setDisabled(this.deptTreeData);
          }
        }
      });
    },

    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.getRisktreatmentPage();
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.getRisktreatmentPage();
    },
    handleSwitch() {
      if (this.getRiskCode) {
        this.getPageCode();
      } else {
        this.ruleForm.riskCode = "";
      }
    },
    getPageCode() {
      // 获取编码
      getPageCode({}).then((res) => {
        this.$set(this.ruleForm, "riskCode", res.data.data || "");
      });
    },
    handleCheckChange() {
      let checkedKeys = this.$refs.deptTree.getCheckedKeys();
      this.form.deptIdList = checkedKeys;
    },
    handleDepSelect(e) {},
    rowDel(row) {
      this.DelLsitid = row.id;
      this.dialogVisible3 = true;
    },
    handleSource(e) {
      let data = this.riskSourceList.filter((item) => e == item.sourceCode)[0];
      // this.ruleForm.sourceCode = this.ruleForm.riskSource.sourceCode;
      this.ruleForm.sourceId = data.id;
      this.ruleForm.sourceName = data.sourceName;
      this.ruleForm.sourceTypeId = data.sourceTypeId;
    },
    // 完成
    headComplete() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.ruleForm.sourceId) {
            this.ruleForm.sourceCode = this.ruleForm.riskSource.sourceCode;
            this.ruleForm.sourceId = this.ruleForm.riskSource.id;
            this.ruleForm.sourceName = this.ruleForm.riskSource.sourceName;
            this.ruleForm.sourceTypeId = this.ruleForm.riskSource.sourceTypeId;
          }
          let parmas = {
            ...this.ruleForm,
            libraryStatus: "FINISHED",
            organizationId: this.userInfo.dept_id,
            createDept: this.userInfo.dept_id,
            needCalculate: this.needCalculate,
          };
          if (this.nowRuleFormId) {
            parmas.id = this.nowRuleFormId;
          }
          addPage(parmas).then((res) => {
            if (res.data.code == 200) {
              this.nowRuleFormId = res.data.data;
              this.$message({
                message: "保存成功",
                type: "success",
              });
              this.getRisktreatmentPage();
              // this.getdemotree();
            }
          });
        }
      });
      this.$set(this, "treeData", this.treeData);
    },
    // 取消
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    // 保存
    headSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.ruleForm.sourceId) {
            this.ruleForm.sourceCode = this.ruleForm.riskSource.sourceCode;
            this.ruleForm.sourceId = this.ruleForm.riskSource.id;
            this.ruleForm.sourceName = this.ruleForm.riskSource.sourceName;
            this.ruleForm.sourceTypeId = this.ruleForm.riskSource.sourceTypeId;
          }
          let event = JSON.parse(JSON.stringify(this.ruleForm.event));

          let foundItems = [];
          event.forEach((key) => {
            let item = this.eventList.find((item) => item.dictKey === key);
            if (item) {
              foundItems.push(item.dictValue);
            }
          });
          let parmas = {
            ...this.ruleForm,
            status: "1",
            organizationId: this.organizationId,
            createDept: this.userInfo.dept_id,
            needCalculate: this.needCalculate,
            event: event.join(","),
            eventName: foundItems.join(","),
            sourceId: this.ruleForm.sourceId,
          };
          if (this.nowRuleFormId) {
            parmas.id = this.nowRuleFormId;
          }
          addPage(parmas).then((res) => {
            if (res.data.code == 200) {
              this.nowRuleFormId = res.data.data;
              this.$message({
                message: "保存草稿成功",
                type: "success",
              });
              this.getRisktreatmentPage();
              // this.getdemotree();
            }
          });
        }
      });
      this.$set(this, "treeData", this.treeData);
    },
    selectionChange(arr) {
      this.ids = arr
        .map((item) => {
          return item.id;
        })
        .join(",");
    },
    handleNodeClickListTree(node, obj) {
      this.listForm.treatmentTypeName = node["treatmentTypeName"];
      this.listForm.treatmentTypeId = node["id"];
      this.defaultExpandedKey = [];
      this.$refs.selectTree.blur();
    },
    clearHandle() {
      this.listForm.treatmentTypeName = "";
      this.listForm.treatmentTypeId = "";
      this.defaultExpandedKey = [];
      this.clearSelected();
    },
    clearSelected() {
      let allNode = document.querySelectorAll("#tree-option .el-tree-node");
      allNode.forEach((element) => element.classList.remove("is-current"));
    },
    onChangePerson(arr) {
      this.listForm.respUserName = arr[1].fullName;
      this.listForm.respNickName = arr[1].nickName;
    },

    getRiskTreatmentTypeDetail() {
      getRiskTreatmentTypeDetail({ id: this.nowNodeId }).then((res) => {
        this.treeForm = res.data.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add {
  height: 100%;
}

::v-deep .tree-box {
  .common_tree_handle {
    height: 100%;

    .el-tree {
      height: calc(100% - 112px) !important;
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }

  #gridLayout {
    height: calc(100% - 90px) !important;
  }

  .avue-crud {
    height: 100% !important;

    .el-card {
      height: 100% !important;
    }

    .el-card__body {
      height: 100% !important;

      .el-form {
        height: 100% !important;

        .el-table {
          height: 100% !important;
        }
      }
    }

    .el-table__empty-text {
      height: 100% !important;
    }
  }
}

.el-main {
  height: 100%;
  overflow: hidden;
}

::v-deep .DeptDialog .avue-crud .el-table {
  height: 55vh !important;
  max-height: 55vh !important;
}
</style>
